import { useContext } from 'react';

import SlideEditArea from '@editor/slide-edit-area';
import SlideToolsArea from '@editor/slide-tools-area';

import { TitleMiniObject } from '@slides/layouts/title-mini-object';
import { TitleOneObject } from '@slides/layouts/title-one-object';
import { Title, WordsCloud, CommentInput } from '@slides/elements';

import { TextEdit } from '@editor/elements/text-edit';
import { TextTools } from '@editor/tools/text-tools';
import { BackgroundTools } from '@editor/tools/background-tools';
import { CommentsTools } from '@editor/tools/comments-tools';

import { WebSocketContext } from '@contexts/WebSocketContext';
import { BroadcastDispatchContext } from '@contexts/BroadcastDispatchContext';
import { UserContext } from '@contexts/UserContext';
import { PresentationContext } from '@contexts/PresentationContext';

import ImageIcon from '@assets/images/layouts-icons/mini/th-ic-4.svg';

export function WordscloudSlideView(props) {
    const [send, _, ready] = useContext(WebSocketContext);
    const dispatch = useContext(BroadcastDispatchContext);
    const user = useContext(UserContext);
    const presentation = useContext(PresentationContext);

    let cleanTextMsg = (text) => {
        text = text.trim();
        text = text.toLowerCase();
        text = text.replace('ё', 'е');
        return text;
    }

    let commentHandle = (text) => {
        let msg = {};

        text = cleanTextMsg(text);

        let allowed = false;
        if (text in props.data.list)
            allowed = true;

        if (!presentation.params.hasOwnProperty('control') || !presentation.params.control || user.role !== 'viewer')
            msg = { action: 'word', params: { text: text, slide: props.slide_num, allowed: true } };
        else
            msg = { action: 'word', params: { text: text, slide: props.slide_num, allowed: allowed } };

        dispatch({ type: 'edit_per_msg', msg: msg });
        send(msg);
    };

    const wordcloud =
        <>
            <WordsCloud words={props.data.list} fontFamily={presentation.params.fontFamily ?? ''}></WordsCloud>
            <CommentInput action={commentHandle} length={20}></CommentInput>
        </>;

    return (
        <>
            <TitleOneObject title={Title(props.data.title)} object={wordcloud} background={props.background}></TitleOneObject>
        </>
    );
}


export function WordscloudSlideMini(props) {
    return (
        <TitleMiniObject title={props.data.title.text} object={ImageIcon}></TitleMiniObject>
    );
}

export function WordscloudSlideEdit(props) {
    const presentation = useContext(PresentationContext);
    
    const TitleInput = TextEdit({ text: props.data.title, element: 'title' });

    const tools = [
        {
            title: 'Заголовок',
            tool: <TextTools meta={props.data.title.meta} element={'title'}></TextTools>
        },
        {
            title: 'Фон',
            tool: <BackgroundTools background={props.background}></BackgroundTools>
        },
        {
            title: 'Облако слов',
            tool: <CommentsTools></CommentsTools>
        }
    ];

    const wordscloud = <WordsCloud words={props.data.list} fontFamily={presentation.params.fontFamily ?? ''}></WordsCloud>;

    return (
        <>
            <SlideEditArea>
                <TitleOneObject title={TitleInput} object={wordscloud} background={props.background}></TitleOneObject>
            </SlideEditArea>

            <SlideToolsArea tools={tools}>
            </SlideToolsArea>
        </>
    );
}