export default function BroadcastReducer(presentation, action) {
    if (action.type !== 'load_presentation' && presentation === null)
        throw Error('Presentation don\'t load');
    else if (action.type === 'load_presentation') {
        return action.presentation;
    } else if (action.type === 'set_voted') {
        sessionStorage.setItem(`voted:${action.uuid}:${action.slide}`, 1);
        return { ...presentation };
    } else if (action.type === 'set_slide_data') {
        switch (presentation.slides[action.slide].type) {
            case 'voting': {
                for (const [key, value] of Object.entries(action.data)) {
                    presentation.slides[action.slide].data.options[key].votes += Number(value);
                }
                return { ...presentation };
            }
            case 'comments': {
                const full_list = action.data.concat(presentation.slides[action.slide].data.list);
                presentation.slides[action.slide].data.list = full_list;

                return { ...presentation };
            }
            case 'wordscloud': {
                presentation.slides[action.slide].data.list = { ...action.data, ...presentation.slides[action.slide].data.list };
                delete presentation.slides[action.slide].data.list[''];

                for (const [key, value] of Object.entries(presentation.slides[action.slide].data.list)) {
                    presentation.slides[action.slide].data.list[key] = Number(value);
                }
                return { ...presentation };
            }
            case 'quiz': {
                presentation.slides[action.slide].data.stoped = action.hasOwnProperty('stoped') ? action.stoped : 0;

                return { ...presentation };
            }
            case 'quiz-images': {
                presentation.slides[action.slide].data.stoped = action.hasOwnProperty('stoped') ? action.stoped : 0;

                return { ...presentation };
            }
            default: {
                return presentation;
            }
        }
    }
    else if (action.type === 'edit_per_msg') {
        switch (action.msg.action) {
            case 'vote': {
                presentation.slides[action.msg.params.slide].data.options[action.msg.params.option].votes += 1;
                return { ...presentation };
            }
            case 'comment': {
                if (!action.msg.params.hasOwnProperty('allowed') || !action.msg.params.allowed)
                    return presentation;

                presentation.slides[action.msg.params.slide].data.list.unshift(action.msg.params);
                return { ...presentation };
            }
            case 'word': {
                if (!action.msg.params.hasOwnProperty('allowed') || !action.msg.params.allowed)
                    return presentation;

                if (!presentation.slides[action.msg.params.slide].data.list.hasOwnProperty(action.msg.params.text))
                    presentation.slides[action.msg.params.slide].data.list[action.msg.params.text] = 0;

                let count = presentation.slides[action.msg.params.slide].data.list[action.msg.params.text] += 1;
                presentation.slides[action.msg.params.slide].data.list = { ...presentation.slides[action.msg.params.slide].data.list, [action.msg.params.text]: count };

                return { ...presentation };
            }
            case 'clear': {
                if (presentation.slides[action.msg.params.slide].type === 'voting') {
                    for (let i = 0; i < presentation.slides[action.msg.params.slide].data.options.length; i++) {
                        presentation.slides[action.msg.params.slide].data.options[i].votes = 0;
                    }

                    sessionStorage.removeItem(`voted:${action.uuid}:${action.msg.params.slide}`);
                }
                else if (presentation.slides[action.msg.params.slide].type === 'comments')
                    presentation.slides[action.msg.params.slide].data.list = [];
                else if (presentation.slides[action.msg.params.slide].type === 'wordscloud')
                    presentation.slides[action.msg.params.slide].data.list = {};

                return { ...presentation };
            }
            case 'stop_activity': {
                presentation.slides[action.msg.params.slide].data.stoped = 1;

                return { ...presentation };
            }
            default: {
                return presentation;
            }
        }
    } else {
        return presentation;
    }
}