import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';

export default function ControlModal(props) {
    let allowHandle = (id) => {
        props.msgs[id].params.allowed = true;
        props.dispatch({ type: 'edit_per_msg', msg: props.msgs[id], uuid: props.uuid });
        props.send(props.msgs[id]);

        removeMsg(id);
    }

    let rejectHandle = (id) => {
        removeMsg(id);
    }

    let removeMsg = (id) => {
        if (!props.msgs[id]) return;

        delete props.msgs[id];

        props.setMsgs({
            ...props.msgs
        });
    }
    return (
        <Modal show={props.show} onHide={props.close} size="xl" centered>
            <Modal.Header closeButton>
                <Modal.Title>Модерация сообщений</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="comment-block">
                    <div className="chat-detail slimscroll">
                        {Object.keys(props.msgs).map((id, i) =>
                            <div key={i} className="media">
                                <div className="media-body">
                                    <div className="control-msg">
                                        <div className='control-text'>
                                            <p className="author"><b>Имя:</b> {props.msgs[id].params.nick || 'без имени'}</p>
                                            <p><b>Сообщение:</b> {props.msgs[id].params.text}</p>
                                        </div>
                                        <div className="control-btn">
                                            <button className="btn btn-primary" title="Принять" onClick={() => allowHandle(id)}>v</button>
                                            <button className="btn btn-danger"  title="Отклонить" onClick={() => rejectHandle(id)}>х</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </Modal.Body>
        </Modal>

    );
}