import { useContext, useState, useEffect } from 'react';

import SlideEditArea from '@editor/slide-edit-area';
import SlideToolsArea from '@editor/slide-tools-area';

import { TitleMiniObject } from '@slides/layouts/title-mini-object';
import { TitleOneObject } from '@slides/layouts/title-one-object';
import { Title, QuizOptions } from '@slides/elements';

import { TextEdit } from '@editor/elements/text-edit';
import { TextTools } from '@editor/tools/text-tools';
import { BackgroundTools } from '@editor/tools/background-tools';

import { WebSocketContext } from '@contexts/WebSocketContext';
import { PresentationContext } from '@contexts/PresentationContext';
import { CurrentSlideNumContext } from '@contexts/CurrentSlideNumContext';

import { QuizOptionsTools } from '@editor/tools/quiz-options-tools';

import ImageIcon from '@assets/images/layouts-icons/mini/th-ic-2.svg';


export function QuizSlideView(props) {
    const [send, msg, ready] = useContext(WebSocketContext);
    const presentation = useContext(PresentationContext);
    const current_slide = useContext(CurrentSlideNumContext);

    const [answer, setAnswer] = useState([]);
    const [show, setShow] = useState(false);

    let answerHandle = (i) => {
        if (show)
            return;

        let action = 'vote';
        if (answer.includes(i)) {
            setAnswer(answer.filter((item) => item !== i));
            action = 'unvote';
        }

        else
            setAnswer([...answer, i]);

        let msg = { action: action, params: { option: i, slide: props.slide_num } };
        send(msg);
    };

    useEffect(() => {
        if (presentation !== null)
            setShow(presentation.slides[current_slide].data.stoped == 1 || false);
        setAnswer([]);
    }, [presentation, current_slide]);

    useEffect(() => {
        if (msg && msg.action === 'stop_activity')
            setShow(true);
    }, [msg]);

    const options =
        <QuizOptions
            options={props.data.options}
            answer={answer}
            action={answerHandle}
            show={show}
            results={false}
        />;

    return (
        <>
            <TitleOneObject title={Title(props.data.title)} object={options} background={props.background}></TitleOneObject>
        </>
    );
}

export function QuizSlideMini(props) {
    return (
        <TitleMiniObject title={props.data.title.text} object={ImageIcon}></TitleMiniObject>
    );
}

export function QuizSlideEdit(props) {
    const TitleInput = TextEdit({ text: props.data.title, element: 'title' });

    const tools = [
        {
            title: 'Заголовок',
            tool: <TextTools meta={props.data.title.meta} element={'title'}></TextTools>
        },
        {
            title: 'Викторина',
            tool: <QuizOptionsTools options={props.data.options}></QuizOptionsTools>
        },
        {
            title: 'Фон',
            tool: <BackgroundTools background={props.background}></BackgroundTools>
        }
    ];

    const options =
        <QuizOptions
            options={props.data.options}
            answer={[]}
            action={() => { }}
            show={false}
            results={true}
        />;

    return (
        <>
            <SlideEditArea>
                <TitleOneObject title={TitleInput} object={options} background={props.background}></TitleOneObject>
            </SlideEditArea>

            <SlideToolsArea tools={tools}>
            </SlideToolsArea>
        </>
    );
}