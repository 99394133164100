import { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';

import Form from 'react-bootstrap/Form';

import { CurrentSlideNumContext } from '@contexts/CurrentSlideNumContext';
import { EditDispatchContext } from '@contexts/EditDispatchContext';

import { saveImageFile } from '@utils/presentation';


export const QuizImagesOptionsTools = (props) => {
    const { uuid } = useParams();

    const current_slide = useContext(CurrentSlideNumContext);
    const dispatch = useContext(EditDispatchContext);

    const [options, setOptions] = useState([...props.options]);

    let changeFile = async (e, i) => {
        let filename = await saveImageFile(uuid, e.target.files[0]);
        if (filename != null) {
            filename = '/' + filename;
            dispatch({ type: 'edit_slide_img_option', slide: current_slide, option: i, file: filename });
            options[i].file = filename;
            setOptions([...props.options]);
        }
    }

    let changeCorrectHandle = (i, checked) => {
        if (i < 0 || i >= options.length)
            return;

        for (let j = 0; j < options.length; j++)
            options[j].correct = false;

        options[i].correct = checked;
        setOptions([...options]);
        dispatch({ type: 'edit_slide_option', slide: current_slide, option: i, property: 'correct', value: checked });
    }

    let clearSlideDataHandle = () => {
        dispatch({ type: 'clear_slide_voting', slide: current_slide });
    }

    return (
        <>
            <ul className='vote-edit'>
                {(options || []).map((option, i) =>
                    <li key={i} className='mb-5'>
                        <div className="custom-file">
                            <input type="file" onChange={(e) => changeFile(e, i)} className="custom-file-input" id={`load_image_file_opt${i}`} accept="image/*" />
                            <label className="custom-file-label" htmlFor={`load_image_file_opt${i}`}>{option.file == '' ? 'Выбрать файл' : 'Изменить файл'}</label>
                        </div>
                        <Form>
                            <Form.Check
                                defaultChecked={option.correct}
                                checked={option.correct}
                                onChange={(e) => changeCorrectHandle(i, e.target.checked)}
                                type="checkbox"
                                id={`control-switch-opt${i}`}
                                label="Верный ответ"
                            />
                        </Form>
                    </li>
                )}
            </ul>
            <button className='btn btn-danger mt-3' onClick={clearSlideDataHandle}>Очистить данные</button>

        </>
    );
}