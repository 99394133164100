import Accordion from 'react-bootstrap/Accordion';

import { PresentationTools } from '@editor/tools/presentation-tools';

export default function SlideToolsArea(props) {
    return (
        <>
            <div className="right-block card-s">
                <Accordion alwaysOpen defaultActiveKey={0}>
                    <Accordion.Item>
                        <Accordion.Header>Презентация</Accordion.Header>
                        <Accordion.Body>
                            <PresentationTools />
                        </Accordion.Body>
                    </Accordion.Item>
                    {(props.tools || []).map((t, i) =>
                        <Accordion.Item eventKey={i} key={i}>
                            <Accordion.Header>{t.title}</Accordion.Header>
                            <Accordion.Body>
                                {t.tool}
                            </Accordion.Body>
                        </Accordion.Item>
                    )}
                </Accordion>
            </div>
        </>
    );
}