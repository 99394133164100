import { useContext, useState, useEffect } from 'react';

import SlideEditArea from '@editor/slide-edit-area';
import SlideToolsArea from '@editor/slide-tools-area';

import { TitleMiniObject } from '@slides/layouts/title-mini-object';
import { TitleOneObject } from '@slides/layouts/title-one-object';
import { Title, QuizImagesOptions } from '@slides/elements';

import { TextEdit } from '@editor/elements/text-edit';
import { TextTools } from '@editor/tools/text-tools';
import { BackgroundTools } from '@editor/tools/background-tools';

import { WebSocketContext } from '@contexts/WebSocketContext';
import { PresentationContext } from '@contexts/PresentationContext';
import { CurrentSlideNumContext } from '@contexts/CurrentSlideNumContext';

import { QuizImagesOptionsTools } from '@editor/tools/quiz-images-options-tools';

import ImageIcon from '@assets/images/layouts-icons/mini/th-ic-2.svg';


export function QuizImagesSlideView(props) {
    const [send, msg, ready] = useContext(WebSocketContext);
    const presentation = useContext(PresentationContext);
    const current_slide = useContext(CurrentSlideNumContext);

    const [answer, setAnswer] = useState(null);
    const [show, setShow] = useState(false);

    let answerHandle = (i) => {
        if (show)
            return;

        let msg = null;
        if (answer !== null)
        {
            msg = { action: 'unvote', params: { option: answer, slide: props.slide_num } };
            send(msg);
        }
            
        setAnswer(i);

        msg = { action: 'vote', params: { option: i, slide: props.slide_num } };
        send(msg);
    };

    useEffect(() => {
        if (presentation !== null)
            setShow(presentation.slides[current_slide].data.stoped == 1 || false);
        setAnswer(null);
    }, [presentation, current_slide]);

    useEffect(() => {
        if (msg && msg.action === 'stop_activity')
            setShow(true);
    }, [msg]);

    const options =
        <QuizImagesOptions
            options={props.data.options}
            answer={answer}
            action={answerHandle}
            show={show}
            results={false}
        />;

    return (
        <>
            <TitleOneObject title={Title(props.data.title)} object={options} background={props.background}></TitleOneObject>
        </>
    );
}

export function QuizImagesSlideMini(props) {
    return (
        <TitleMiniObject title={props.data.title.text} object={ImageIcon}></TitleMiniObject>
    );
}

export function QuizImagesSlideEdit(props) {
    const TitleInput = TextEdit({ text: props.data.title, element: 'title' });

    const tools = [
        {
            title: 'Заголовок',
            tool: <TextTools meta={props.data.title.meta} element={'title'}></TextTools>
        },
        {
            title: 'Выбор из двух',
            tool: <QuizImagesOptionsTools options={props.data.options}></QuizImagesOptionsTools>
        },
        {
            title: 'Фон',
            tool: <BackgroundTools background={props.background}></BackgroundTools>
        }
    ];

    const options =
        <QuizImagesOptions
            options={props.data.options}
            answer={null}
            action={() => { }}
            show={false}
            results={true}
        />;

    return (
        <>
            <SlideEditArea>
                <TitleOneObject title={TitleInput} object={options} background={props.background}></TitleOneObject>
            </SlideEditArea>

            <SlideToolsArea tools={tools}>
            </SlideToolsArea>
        </>
    );
}