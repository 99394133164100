
import { useContext } from 'react';

import { CurrentSlideNumContext } from '@contexts/CurrentSlideNumContext';
import { EditDispatchContext } from '@contexts/EditDispatchContext';


export const CommentsTools = () => {
    const current_slide = useContext(CurrentSlideNumContext);
    const dispatch = useContext(EditDispatchContext);

    let clearSlideDataHandle = () => {
        dispatch({ type: 'clear_slide_comments', slide: current_slide });
    }
    return (
        <>
           <button className='btn btn-danger mt-3' onClick={clearSlideDataHandle}>Очистить данные</button>
        </>
    );
}