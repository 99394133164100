
import { useContext } from 'react';

import { useParams } from 'react-router-dom';

import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';

import { EditDispatchContext } from '@contexts/EditDispatchContext';
import { PresentationContext } from '@contexts/PresentationContext';

import { saveImageFile } from '@utils/presentation';

export const PresentationTools = () => {
    const { uuid } = useParams();

    const dispatch = useContext(EditDispatchContext);
    const presentation = useContext(PresentationContext);

    const fonts = { 'Nunito': 'Nunito', 'Gilroy': 'Gilroy', 'Montserrat': 'Montserrat','PlayfairDisplay': 'PlayfairDisplay', 'PTSansNarrow': 'PTSansNarrow', 'Roboto': 'Roboto', 'RubikBeastly': 'RubikBeastly', 'sans-serif': 'sans-serif', 'serif': 'serif', 'cursive': 'cursive' };

    let changeSwitch = (e) => {
        let param = e.target.id.split('-')[0];
        dispatch({ type: 'edit_presentation_params', param: param, value: e.target.checked });
    }

    let changeFile = async (e) => {
        let filename = await saveImageFile(uuid, e.target.files[0]);
        if (filename != null)
            dispatch({ type: 'edit_presentation_params', param: 'logo', value: filename });
    }

    let onSelectFont = (eventKey, event) => {
        dispatch({ type: 'edit_presentation_params', param: 'font', value: eventKey });
    }

    return (
        <>
            {presentation &&
                <>
                    <Form onChange={changeSwitch}>
                        <Form.Check
                            defaultChecked={presentation.params.hasOwnProperty('control') ? presentation.params.control : false}
                            type="switch"
                            id="control-switch"
                            label="Включить модерацию"
                        />

                        <Form.Check
                            defaultChecked={presentation.params.hasOwnProperty('showlogo') ? presentation.params.showlogo : true}
                            type="switch"
                            id="showlogo-switch"
                            label="Отображать логотип"
                        />
                    </Form>

                    <div className="custom-file mt-2">
                        <span className="label-rb">Логотип</span>
                        <div className='ps'>
                            <input type="file" onChange={changeFile} className="custom-file-input" id="load_image_logo_file" accept="image/*" />
                            <label className="custom-file-label" htmlFor="load_image_logo_file">{presentation.params.logo == null ? 'Выбрать файл' : 'Изменить файл'}</label>
                        </div>
                    </div>

                    <Dropdown onSelect={onSelectFont}>
                    <div className='space-between'>
                        <span className="label-rb">Шрифт</span>

                        <Dropdown.Toggle variant='primary' id="dropdown-fonts-button2">
                            <span style={{ fontFamily: presentation.params.font ?? Object.keys(fonts)[0] }}>{fonts[presentation.params.font] ?? fonts[Object.keys(fonts)[0]]}</span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {Object.keys(fonts).map((font, i) =>
                                <Dropdown.Item eventKey={font} key={i}><span style={{ fontFamily: fonts[font] }}>{font}</span></Dropdown.Item>
                            )}
                        </Dropdown.Menu>
                        </div>
                    </Dropdown>
                </>
            }
        </>
    );
}