import { useState, useContext } from 'react';

import { CurrentSlideNumContext } from '@contexts/CurrentSlideNumContext';
import { EditDispatchContext } from '@contexts/EditDispatchContext';


export const OpinionsTools = (props) => {
    const current_slide = useContext(CurrentSlideNumContext);
    const dispatch = useContext(EditDispatchContext);

    const [correct, setCorrect] = useState(props.correct);

    let cleanTextMsg = (text) => {
        if (text === null)
            return null;
        
        text = text.trim();
        text = text.toLowerCase();
        text = text.replace('ё', 'е');
        return text;
    }

    let changeTextHandle = (value) => {
        if (value.length > 100)
            return;

        setCorrect(value);
    }

    let saveText = () => {
        dispatch({ type: 'edit_slide_opinion_correct', slide: current_slide, value: cleanTextMsg(correct) });
    }

    let clearSlideDataHandle = () => {
        dispatch({ type: 'clear_slide_comments', slide: current_slide });
    }

    return (
        <>
            <span className="label-rb">Текст варианта</span>
            <textarea value={correct} className='form-control'
                onBlur={saveText}
                onChange={(e) => changeTextHandle(e.target.value)}
            />

            <button className='btn btn-danger mt-3' onClick={clearSlideDataHandle}>Очистить данные</button>
        </>
    );
}