import * as Content from './types/content-slide';
import * as Head from './types/head-slide';
import * as Comments from './types/comments-slide';
import * as Opinions from './types/opinions-slide';
import * as Wordscloud from './types/wordscloud-slide';
import * as Quiz from './types/quiz-slide';
import * as QuizImages from './types/quiz-images-slide';
import * as Voting from './types/voting-slide';
import * as QrCode from './types/qrcode-slide';
import * as Pdf from './types/pdf-slide';


export const SlideView = (props) => {
    return SlideSwitcher(props);
}

export const SlideMini = (props) => {
    return SlideSwitcher(props, 'Mini');
}

export const SlideEdit = (props) => {
    return SlideSwitcher(props, 'Edit');
}

const SlideSwitcher = (props, spec = 'view') => {
    spec = spec.charAt(0).toUpperCase() + spec.slice(1).toLowerCase();

    switch (props.type) {
        case 'content': {
            const SlideComponent = Content['ContentSlide' + spec];
            return <SlideComponent {...props} />;
        }
        case 'head': {
            const SlideComponent = Head['HeadSlide' + spec];
            return <SlideComponent {...props} />;
        }
        case 'comments': {
            const SlideComponent = Comments['CommentsSlide' + spec];
            return <SlideComponent {...props} />;
        }
        case 'opinions': {
            const SlideComponent = Opinions['OpinionsSlide' + spec];
            return <SlideComponent {...props} />;
        }
        case 'wordscloud': {
            const SlideComponent = Wordscloud['WordscloudSlide' + spec];
            return <SlideComponent {...props} />;
        }
        case 'voting': {
            const SlideComponent = Voting['VotingSlide' + spec];
            return <SlideComponent {...props} />;
        }
        case 'quiz': {
            const SlideComponent = Quiz['QuizSlide' + spec];
            return <SlideComponent {...props} />;
        }
        case 'quiz-images': {
            const SlideComponent = QuizImages['QuizImagesSlide' + spec];
            return <SlideComponent {...props} />;
        }
        case 'qrcode': {
            const SlideComponent = QrCode['QrCodeSlide' + spec];
            return <SlideComponent {...props} />;
        }
        case 'pdf': {
            const SlideComponent = Pdf['PdfSlide' + spec];
            return <SlideComponent {...props} />;
        }
        default: {
            return <></>;
        }
    }
}