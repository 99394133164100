import { useState, useContext } from 'react';
import Form from 'react-bootstrap/Form';

import { CurrentSlideNumContext } from '@contexts/CurrentSlideNumContext';
import { EditDispatchContext } from '@contexts/EditDispatchContext';


export const QuizOptionsTools = (props) => {
    const current_slide = useContext(CurrentSlideNumContext);
    const dispatch = useContext(EditDispatchContext);

    const [options, setOptions] = useState([...props.options]);

    let changeTextOptionHandle = (i, value) => {
        if (value.length > 100)
            return;

        options[i].text = value;
        setOptions([...options]);
    }

    let saveTextOption = (i, value) => {
        dispatch({ type: 'edit_slide_option', slide: current_slide, option: i, property: 'text', value: value });
    }

    let deleteHandle = (i) => {
        if (i >= 0 && i < options.length && options.length > 2) {
            options.splice(i, 1);
            setOptions([...options]);

            dispatch({ type: 'delete_slide_option', slide: current_slide, option: i });
        }
    }

    let addHandle = () => {
        if (options.length >= 6)
            return;

        let option = { text: '', correct: false };

        options.push(option);
        setOptions([...options]);

        dispatch({ type: 'add_slide_option', slide: current_slide, option: option });
    }

    let changeCorrectHandle = (i, checked) => {
        if (i < 0 || i >= options.length)
            return;
        
        options[i].correct = checked;
        setOptions([...options]);
        dispatch({ type: 'edit_slide_option', slide: current_slide, option: i, property: 'correct', value: checked });
    }

    let clearSlideDataHandle = () => {
        dispatch({ type: 'clear_slide_voting', slide: current_slide });
    }

    return (
        <>
            <ul className='vote-edit'>
                {(options || []).map((option, i) =>
                    <li key={i} className='mb-5'>
                        <span className="label-rb">Текст варианта</span>
                        <textarea value={option.text} className='form-control'
                            onBlur={(e) => saveTextOption(i, e.target.value)}
                            onChange={(e) => changeTextOptionHandle(i, e.target.value)}
                        />

                        <Form onChange={(e) => changeCorrectHandle(i, e.target.checked)}>
                            <Form.Check
                                defaultChecked={option.correct}
                                type="checkbox"
                                id={`control-switch-opt${i}`}
                                label="Верный ответ"
                            />
                        </Form>

                        <button className="btn btn-danger" onClick={() => deleteHandle(i)} disabled={options.length <= 2}>Удалить</button>
                    </li>
                )}
            </ul>
            <button className="btn btn-primary waves-effect waves-light" onClick={addHandle} disabled={options.length >= 6}>Добавить</button>
            <br/>
            <button className='btn btn-danger mt-3' onClick={clearSlideDataHandle}>Очистить данные</button>
        </>
    );
}