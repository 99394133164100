import { useState, useEffect } from 'react';
import QRCode from 'react-qr-code';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Alert from 'react-bootstrap/Alert';

import ReactWordcloud from 'react-wordcloud';

export const Text = (props) => {
    return (
        <div className='text-block'>
            <p style={{ ...props.meta, whiteSpace: 'pre-line' }}>{props.text}</p>
        </div>
    );
}

export const Title = (props) => {
    return (
        <p style={props.meta}>{props.text}</p>
    );
}

export const Comments = (props) => {
    return (
        <div className="comment-block">
            <div className="chat-detail slimscroll">
                {(props.comments).map((comment, i) =>
                    <div key={i} className="media">
                        <div className="media-body">
                            <div className="chat-msg">
                                {comment.role === 'speaker' ?
                                    <div className="msg-author">{comment.allowed || ''}{comment.nick}</div>
                                    :
                                    <div className="msg-author">{comment.nick}</div>
                                }
                                <p>{comment.text}</p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export const Opinions = (props) => {
    return (
        <div className="comment-block">
            <div className="chat-detail slimscroll">
                {(props.comments).map((comment, i) =>
                    <div key={i} className="media">
                        <div className="media-body">
                            <div style={{ backgroundColor: (comment.text === props.correct ? 'green' : 'red') }} className="chat-msg">
                                <div className="msg-author">{comment.nick}</div>
                                <p>{comment.text}</p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export let Opinion = (props) => {
    const [variant, setVariant] = useState('secondary');
    useEffect(() => {
        if (props.show && props.correct != null)
            setVariant(props.answer === props.correct ? 'success' : 'danger');

        console.log(props.show, props.answer)
    }, [props.show, props.answer]);

    return (
        <>
            {props.answer !== null ?
                <Alert className="opinion" variant={variant}>
                    Ваш ответ: {props.answer}
                    {variant == 'success'
                        ? '   ✅' :
                        variant == 'danger' ?
                            <>   ❌<br /><br />Верный ответ: {props.correct}   ✅</>
                            : ''
                    }
                </Alert>
                : ''}
            {
                props.show && props.answer === null ?
                    <Alert className="opinion" variant='success'>
                        Верный ответ: {props.correct}   ✅
                    </Alert> : ''
            }
        </>
    );
}

export let WordsCloud = (props) => {
    const [words, setWords] = useState([]);
    const [words_data, setWordsData] = useState([]);

    let formatWords = (words) => {
        let result = [];
        for (const word in words) {
            result.push({ text: word, value: words[word] });
        }

        return result;
    }

    let updateWords = () => {
        if (JSON.stringify(words) !== JSON.stringify(words_data)) {
            setWords([...words_data]);
        }
    }

    // useEffect(() => {
    //     const interval_id = setInterval(() => {
    //         updateWords();
    //     }, 3000);

    //     return () => {
    //         clearInterval(interval_id);
    //     };
    // }, [words_data]);

    useEffect(() => {
        // setWordsData(formatWords(props.words));
        if (JSON.stringify(words) !== JSON.stringify(props.words))
            setWords(formatWords(props.words));

        //setWords(formatWords(props.words));
    }, [props.words]);

    // const options = {
    //     rotations: 2,
    //     rotationAngles: [-90, 0],
    //     fontSizes: [40, 75],
    //     fontWeight: 'bold',
    //     fontFamily: props.fontFamily,
    //     deterministic: true,
    //     randomSeed: '1234',
    //     padding: 50,
    // };

    const options = {
        enableTooltip: true,
        deterministic: true,
        randomSeed: '1234',
       // fontFamily: "'Inter',Roboto,'Helvetica Neue',system-ui,-apple-system,'Segoe UI','Noto Sans','Liberation Sans',Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji'",
        //fontFamily: props.fontFamily,
        fontFamily: "Gilroy",
        fontSizes: [16, 70], 
        fontStyle: "normal",
        fontWeight: "500",
        padding: 15,
        rotations: 2,
        rotationAngles: [0, 90],
        scale: "sqrt",
        spiral: "archimedean",
        transitionDuration: 100,

    };

    const resizeStyle = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "70%",
    };


    return (
        <div style={resizeStyle}>
            <div style={{ width: "100%", height: "100%" }} className='cloud-word'>
                <ReactWordcloud words={words} options={options} />
            </div>
        </div>
    );
}

export let QrCode = (props) => {
    return (
        <QRCode value={props.link} />
    );
};

export let Image = (props) => {
    return (
        <div className="image-block" style={{ backgroundImage: props.file !== '' ? `url(/${props.file})` : '' }}></div>
    );
}

export let Options = (props) => {
    return (
        <>
            <ButtonGroup vertical>
                {(props.options).map((option, i) =>
                    <button className="btn-voite" key={i} style={{ backgroundColor: option.color }} onClick={() => props.action(i)} disabled={props.voted}>{option.text}</button>
                )}
            </ButtonGroup>
        </>
    );
}

export let QuizOptions = (props) => {
    const [variants, setVariants] = useState(Array(props.options.length).fill('start'));
    const [sum, setSum] = useState(0);

    useEffect(() => {
        let sum_ = props.options.reduce(
            (accumulator, currentValue) => accumulator + currentValue.votes,
            0,
          );
        setSum(sum_);

        let new_variants = props.options.map((option, i) => {
            if (props.show) {
                return option.correct ? 'true' : 'false';
            } else {
                return props.answer.includes(i) ? 'selected' : 'start';
            }
        });

        setVariants(new_variants);
    }, [props.show, props.answer, props.options]);

    return (
        <div className='choise-img'>
            {props.options.map((option, i) =>
                <button key={i} className={variants[i]} onClick={() => props.action(i)}>{option.text}{props.results && <div>{sum > 0 ? Math.round(option.votes / sum * 100) : 0} %</div>}</button>
            )}
        </div>
    );
}

export let QuizImagesOptions = (props) => {
    const [variants, setVariants] = useState(Array(props.options.length).fill('start'));
    const [sum, setSum] = useState(0);

    useEffect(() => {
        let sum_ = props.options.reduce(
            (accumulator, currentValue) => accumulator + currentValue.votes,
            0,
          );
        setSum(sum_);

        let new_variants = props.options.map((option, i) => {
            if (props.show) {
                return option.correct ? 'true' : 'false';
            } else {
                return props.answer === i ? 'selected' : 'start';
            }
        });

        setVariants(new_variants);
    }, [props.show, props.answer, props.options]);

    return (
        <>
            <p> </p>
            <div className='choise-img'>
                {props.options.map((option, i) =>
                    <button key={i} className={variants[i]} onClick={() => props.action(i)}>
                        <img src={option.file} />
                        {props.results && <div>{sum > 0 ? Math.round(option.votes / sum * 100) : 0} %</div>}
                    </button>
                )}
            </div>
        </>
    );
}


export let CommentInput = (props) => {
    const [comment, setComment] = useState('');

    let sendHandle = () => {
        if (comment !== '') {
            props.action(comment);
            setComment('');
        }
    }

    let changeCommentHandle = (value) => {
        if (value.length <= props.length)
            setComment(value);
    }

    let onKeyDownHandle = (e) => {
        if (e.key === 'Enter')
            sendHandle();
    }

    return (
        <>
            <div className='space-between py-2 comment-input'>
                <input
                    type='text'
                    onChange={(e) => changeCommentHandle(e.target.value)}
                    value={comment}
                    onKeyDown={onKeyDownHandle}
                    className='input-comment form-control'
                ></input>
                <button onClick={sendHandle} className='btn btn-primary waves-effect waves-light'>
                    Отправить
                </button>
            </div>
        </>
    );
}