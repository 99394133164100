import { useContext, useState, useEffect } from 'react';

import SlideEditArea from '@editor/slide-edit-area';
import SlideToolsArea from '@editor/slide-tools-area';

import { TitleMiniObject } from '@slides/layouts/title-mini-object';
import { TitleOneObject } from '@slides/layouts/title-one-object';
import { Title, Opinion, CommentInput, Opinions } from '@slides/elements';

import { TextEdit } from '@editor/elements/text-edit';
import { TextTools } from '@editor/tools/text-tools';
import { BackgroundTools } from '@editor/tools/background-tools';
import { OpinionsTools } from '@editor/tools/opinions-tools';

import { WebSocketContext } from '@contexts/WebSocketContext';
import { BroadcastDispatchContext } from '@contexts/BroadcastDispatchContext';
import { UserContext } from '@contexts/UserContext';
import { PresentationContext } from '@contexts/PresentationContext';
import { CurrentSlideNumContext } from '@contexts/CurrentSlideNumContext';

import ImageIcon from '@assets/images/layouts-icons/mini/th-ic-4.svg';

export function OpinionsSlideView(props) {
    const [send, msg, ready] = useContext(WebSocketContext);
    const dispatch = useContext(BroadcastDispatchContext);
    const presentation = useContext(PresentationContext);
    const current_slide = useContext(CurrentSlideNumContext);
    const user = useContext(UserContext);

    const [answer, setAnswer] = useState(null);
    const [show, setShow] = useState(false);

    let cleanTextMsg = (text) => {
        text = text.trim();
        text = text.toLowerCase();
        text = text.replace('ё', 'е');
        return text;
    }

    let commentHandle = (text) => {
        if (answer !== null)
            return;

        text = cleanTextMsg(text);

        setAnswer(text);

        let msg = {};
        msg = { action: 'opinion', params: { nick: user.nickname, text: text, slide: props.slide_num } };
        send(msg);
    };

    useEffect(() => {
        if (presentation !== null)
            setShow(presentation.slides[current_slide].data.stoped == 1 || false);
    }, [presentation, current_slide]);

    useEffect(() => {
        if (msg && msg.action === 'stop_activity')
            setShow(true);
    }, [msg]);

    const opinion =
        <>
            <Opinion correct={props.data.correct} answer={answer} show={show}></Opinion>
            <CommentInput action={commentHandle} length={100}></CommentInput>
        </>;

    return (
        <>
            <TitleOneObject title={Title(props.data.title)} object={opinion} background={props.background}></TitleOneObject>
        </>
    );
}


export function OpinionsSlideMini(props) {
    return (
        <TitleMiniObject title={props.data.title.text} object={ImageIcon}></TitleMiniObject>
    );
}

export function OpinionsSlideEdit(props) {
    const TitleInput = TextEdit({ text: props.data.title, element: 'title' });

    const tools = [
        {
            title: 'Заголовок',
            tool: <TextTools meta={props.data.title.meta} element={'title'}></TextTools>
        },
        {
            title: 'Фон',
            tool: <BackgroundTools background={props.background}></BackgroundTools>
        },
        {
            title: 'Мнения',
            tool: <OpinionsTools correct={props.data.correct}></OpinionsTools>
        }
    ];

    const opinions = <Opinions correct={props.data.correct} comments={props.data.list}></Opinions>;

    return (
        <>
            <SlideEditArea>
                <TitleOneObject title={TitleInput} object={opinions} background={props.background}></TitleOneObject>
            </SlideEditArea>

            <SlideToolsArea tools={tools}>
            </SlideToolsArea>
        </>
    );
}