import { useContext } from 'react';
import { FullscreenContext } from '@contexts/FullscreenContext';
import { PresentationContext } from '@contexts/PresentationContext';


export default function SlideZone(props) {
    const fullscreen = useContext(FullscreenContext);
    const presentation = useContext(PresentationContext);

    return (
        <>
            <div className={fullscreen ? 'slide-zone sz-fullscreen' : 'slide-zone'}
                style={{ ...props.background?.style, fontFamily: presentation.params.font ?? '"Nunito"' }}>
                {props.children}
            </div>
        </>
    );
}